// extracted by mini-css-extract-plugin
export var customText = "t_sc d_dv d_cs d_cg";
export var videoIframeStyle = "t_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "t_rh d_cs d_cg d_Z";
export var customRow = "t_pb d_bD d_bf";
export var quoteWrapper = "t_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "t_nX d_H";
export var masonryImageWrapper = "t_n3";
export var title = "t_sd";
export var Title3Small = "t_sf D_sf D_tr D_tz";
export var Title3Normal = "t_sg D_sg D_tr D_tB";
export var Title3Large = "t_sh D_sh D_tr D_tC";