// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sj d_fp d_bG d_dv";
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignDiscCenter = "v_sk d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sl d_fr d_bH d_dx";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var footerContainer = "v_sm d_dW d_bW";
export var footerContainerFull = "v_sn d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_sp d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_sq d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_sr d_bz d_bJ d_bN d_bL";
export var wide = "v_ss d_cy";
export var right = "v_st d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_sv d_bC d_bP d_bJ";
export var badgeContainer = "v_sw d_bz d_bH d_bP";
export var badge = "v_sx";
export var padding = "v_sy d_c7";
export var end = "v_sz d_bH";
export var linkWrapper = "v_sB d_dB";
export var link = "v_mC d_dB";
export var colWrapper = "v_sC d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_sD d_bz d_bJ";
export var media = "v_sF d_bx d_dy";
export var itemRight = "v_sG";
export var itemLeft = "v_sH";
export var itemCenter = "v_sJ";
export var exceptionWeight = "v_sK D_tT";