// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "B_s6 d_gv d_cs";
export var heroHeaderCenter = "B_gw d_gw d_cs d_dw";
export var heroHeaderRight = "B_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "B_s7 d_gr d_cw";
export var heroParagraphCenter = "B_gs d_gs d_cw d_dw";
export var heroParagraphRight = "B_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "B_s8 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "B_s9 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "B_tb d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "B_tc d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "B_td d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "B_tf D_tf";
export var heroExceptionNormal = "B_tg D_tg";
export var heroExceptionLarge = "B_th D_th";
export var Title1Small = "B_tj D_tj D_tr D_ts";
export var Title1Normal = "B_tk D_tk D_tr D_tt";
export var Title1Large = "B_tl D_tl D_tr D_tv";
export var BodySmall = "B_tm D_tm D_tr D_tL";
export var BodyNormal = "B_tn D_tn D_tr D_tM";
export var BodyLarge = "B_tp D_tp D_tr D_tN";